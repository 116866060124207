/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import capella from "../assets/models/capella.glb";
import { subsystems, forteOpacity, forteTexture } from "../utils/constant";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import state from "../state";
import { TopDivCount } from "../models/DivCount";
import { xrepeat, yrepeat } from "../utils/constant";

export const CalcRotation = (props) => {
  const { depth, height1, height2 } = props.length;
  const { thickness } = props.int_length;
  const alpha1 = Math.atan(
    Math.abs(height2 - height1 - thickness) / (depth - thickness)
  );
  return alpha1;
};

export default function Capella(props) {
  const { nodes, materials } = useGLTF(capella);
  const { systemID } = props;
  const {
    unit,
    int_div_width,
    max_div_width,
    mid_length_limit,
    column_thickness,
    column_width,
    int_depth,
  } = props.int_length;
  const { width, depth, height1, height2 } = props.length;
  const int_width = subsystems[systemID].width;
  const int_height = subsystems[systemID].height;
  const snap = useSnapshot(state);

  const diffHeight = height2 - height1;
  const height = Math.sqrt(depth * depth + diffHeight * diffHeight);
  const rdepth = props.doubleSystem ? depth - 150 : depth - 150;
  const sh = rdepth / int_height;
  const dd = rdepth - int_depth;

  const tmp_count = parseInt(width / int_div_width);
  const tmp_width = width / tmp_count;
  // const rwidth = props.doubleSystem?width - (props.isLarge?450:200):width;
  const rwidth = width - (props.isLarge ? 450 : 200);

  var div_count = 0;
  if (props.modelID === 3 || props.modelID === 5) {
    if (width >= mid_length_limit[0])
      div_count =
        2 * TopDivCount((width - column_thickness * 3) / 2, props.modelID);
    if (width >= mid_length_limit[1])
      div_count =
        3 * TopDivCount((width - column_thickness * 4) / 3, props.modelID);
    if (width < mid_length_limit[0])
      div_count = TopDivCount(width - column_thickness * 2, props.modelID);
  } else if (props.modelID === 6 || props.modelID === 7) {
    div_count = TopDivCount(width - column_width * 2, props.modelID);
  } else if (props.modelID === 2 || props.modelID === 8) {
    div_count =
      width <= mid_length_limit[0]
        ? TopDivCount(width, props.modelID)
        : 2 * TopDivCount(width / 2, props.modelID);
  } else {
    div_count = tmp_width > max_div_width ? tmp_count + 1 : tmp_count;
  }

  state.subSystemWidth[props.ID] = width;
  state.subSystemHeight[props.ID] = height;

  const area = (width * Math.max(depth, 3000)) / 1000 / 1000;
  var width1 = rwidth;
  var width2 = rwidth;
  var width3 = rwidth;
  var width4 = rwidth;
  if (area > 18) {
    width2 = parseInt(div_count / 2);
    width1 = div_count - width2;
    width1 = (rwidth * width1) / div_count;
    width2 = (rwidth * width2) / div_count;
  }

  if (area > 36) {
    width1 = parseInt(div_count / 4);
    width2 = width1;
    width3 = (div_count - width1 * 2) / 2;
    width1 = (rwidth * width1) / div_count;
    width3 = (rwidth * width3) / div_count;
    width2 = width1;
    width4 = width3;
  }

  const sw = rwidth / int_width;
  const sw1 = width1 / int_width;
  const sw2 = width2 / int_width;
  const sw3 = width3 / int_width;
  const sw4 = width4 / int_width;

  const texture = useTexture(forteTexture);
  texture.rotation = Math.PI;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  const material_texture = new THREE.MeshPhysicalMaterial({
    map: texture,
    transparent: true,
    opacity: forteOpacity,
  });

  state.columns.systemId[props.ID] = props.systemID;
  state.columns.systemId[props.ID + 1] = props.systemID;
  state.columns.systemId[props.ID + 2] = props.systemID;
  state.columns.systemId[props.ID - 1] = props.systemID;

  const p1 = Math.min(100, snap.columns.systemPos[props.ID]);
  const p2 = Math.min(100, snap.columns.systemPos[props.ID + 1]);
  const p3 = Math.min(100, snap.columns.systemPos[props.ID + 2]);
  const p4 = Math.min(100, snap.columns.systemPos[props.ID - 1]);

  const texture1 = useTexture(props.texture);
  texture1.wrapS = THREE.RepeatWrapping;
  texture1.wrapT = THREE.RepeatWrapping;
  texture1.repeat.set(xrepeat, yrepeat);
  texture1.rotation = Math.PI / 2;
  const material_texture1 = new THREE.MeshPhysicalMaterial({
    map: texture1,
  });

  const list = [];
  const list1 = [];
  const list2 = [];
  const list3 = [];
  const list4 = [];

  if (area >= 18 && area < 36) {
    if (
      (props.origin && props.systemVisible[0]) ||
      (!props.origin && props.systemVisible[2])
    ) {
      list1.push(
        <group scale={[sw1, 1, sh]}>
          <mesh
            geometry={nodes.glass.geometry}
            material={material_texture}
            position={[-0.96, -1.42, -18.97]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.01, (0.01 * (100 - p1)) / 100, 0.01]}
            material-color={snap.systemColor[props.ID]}
          />
          <mesh
            geometry={nodes.windowprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor002.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor003.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor005.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor004.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor001.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor006.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.animatedprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85 - (34.15 * p1) / 100]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
        </group>
      );
    }
    if (
      (props.origin && props.systemVisible[1]) ||
      (!props.origin && props.systemVisible[3])
    ) {
      list2.push(
        <group scale={[sw2, 1, sh]}>
          <mesh
            geometry={nodes.glass.geometry}
            material={material_texture}
            position={[-0.96, -1.42, -18.97]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.01, (0.01 * (100 - p2)) / 100, 0.01]}
            material-color={snap.systemColor[props.ID]}
          />
          <mesh
            geometry={nodes.windowprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor002.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor003.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor005.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor004.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor001.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor006.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.animatedprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85 - (34.15 * p2) / 100]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
        </group>
      );
    }
  } else if (area < 18) {
    list.push(
      <group scale={[sw * 1, 1, sh * 1]}>
        <mesh
          geometry={nodes.glass.geometry}
          material={material_texture}
          position={[-0.96, -1.42, -18.97]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[0.01, (0.01 * (100 - p1)) / 100, 0.01]}
          material-color={snap.systemColor[props.ID]}
        />
        <mesh
          geometry={nodes.windowprofile.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[20.71, -1.4, 15.85]}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
          scale={0.001}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.anchor002.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[-21.02, -2.5, 12.64]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.anchor003.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[-21.02, -2.35, -1.79]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.anchor005.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[-21.02, -2.25, -16.18]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.anchor004.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[21.02, -2.35, -1.79]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.anchor001.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[21.02, -2.5, 12.64]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.anchor006.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[21.02, -2.25, -16.18]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
          material-color={snap.structure.color}
        />
        <mesh
          geometry={nodes.animatedprofile.geometry}
          material={props.isTexture ? material_texture1 : materials.profile}
          position={[20.71, -1.4, 15.85 - (34.15 * p1) / 100]}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
          scale={0.001}
          material-color={snap.structure.color}
        />
      </group>
    );
  } else {
    if (
      (props.origin && props.systemVisible[0]) ||
      (!props.origin && props.systemVisible[2])
    ) {
      list1.push(
        <group scale={[sw1, 1, sh]}>
          <mesh
            geometry={nodes.glass.geometry}
            material={material_texture}
            position={[-0.96, -1.42, -18.97]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.01, (0.01 * (100 - p1)) / 100, 0.01]}
            material-color={snap.systemColor[props.ID % 24]}
          />
          <mesh
            geometry={nodes.windowprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor002.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor003.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor005.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor004.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor001.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor006.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.animatedprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85 - (34.15 * p1) / 100]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
        </group>
      );
      list3.push(
        <group scale={[sw3, 1, sh]}>
          <mesh
            geometry={nodes.glass.geometry}
            material={material_texture}
            position={[-0.96, -1.42, -18.97]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.01, (0.01 * (100 - p3)) / 100, 0.01]}
            material-color={snap.systemColor[props.ID % 24]}
          />
          <mesh
            geometry={nodes.windowprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor002.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor003.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor005.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor004.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor001.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor006.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.animatedprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85 - (34.15 * p3) / 100]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
        </group>
      );
    }
    if (
      (props.origin && props.systemVisible[1]) ||
      (!props.origin && props.systemVisible[3])
    ) {
      list2.push(
        <group scale={[sw2, 1, sh]}>
          <mesh
            geometry={nodes.glass.geometry}
            material={material_texture}
            position={[-0.96, -1.42, -18.97]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.01, (0.01 * (100 - p2)) / 100, 0.01]}
            material-color={snap.systemColor[props.ID % 24]}
          />
          <mesh
            geometry={nodes.windowprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor002.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor003.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor005.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor004.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor001.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor006.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.animatedprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85 - (34.15 * p2) / 100]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
        </group>
      );
      list4.push(
        <group scale={[sw4, 1, sh]}>
          <mesh
            geometry={nodes.glass.geometry}
            material={material_texture}
            position={[-0.96, -1.42, -18.97]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.01, (0.01 * (100 - p4)) / 100, 0.01]}
            material-color={snap.systemColor[props.ID % 24]}
          />
          <mesh
            geometry={nodes.windowprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor002.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor003.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor005.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[-21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor004.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.35, -1.79]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor001.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.5, 12.64]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.anchor006.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[21.02, -2.25, -16.18]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.01}
            material-color={snap.structure.color}
          />
          <mesh
            geometry={nodes.animatedprofile.geometry}
            material={props.isTexture ? material_texture1 : materials.profile}
            position={[20.71, -1.4, 15.85 - (34.15 * p4) / 100]}
            rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
            scale={0.001}
            material-color={snap.structure.color}
          />
        </group>
      );
    }
  }

  if (area < 18)
    return (
      <group
        scale={[10, 10, 10]}
        position={[
          200,
          height1 * unit + 30 - props.deltaz,
          dd * unit - (depth * unit) / 2 + 20 + props.deltax,
        ]}
        rotation={[!props.origin ? props.alpha : -props.alpha, props.theta, 0]}
        onPointerOver={(e) => {
          document.body.style.cursor = "pointer";
        }}
        onPointerOut={(e) => {
          document.body.style.cursor = "auto";
        }}
        onClick={(e) => {
          e.stopPropagation();
          state.editingSubSystem = true;
          state.editingSubSystemID = props.ID;
        }}
      >
        {list}
      </group>
    );
  else if (area <= 36) {
    return (
      <>
        <group
          scale={10}
          position={[
            200 - (width2 * unit) / 2 - (props.isLarge ? 12.5 : 0),
            height1 * unit + 30 - props.deltaz,
            dd * unit - (depth * unit) / 2 + 20 + props.deltax,
          ]}
          rotation={[
            !props.origin ? props.alpha : -props.alpha,
            props.theta,
            0,
          ]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID;
          }}
        >
          {list1}
        </group>

        <group
          scale={10}
          position={[
            200 + (width1 * unit) / 2 + (props.isLarge ? 12.5 : 0),
            height1 * unit + 30 - props.deltaz,
            dd * unit - (depth * unit) / 2 + 20 + props.deltax,
          ]}
          rotation={[
            !props.origin ? props.alpha : -props.alpha,
            props.theta,
            0,
          ]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID + 1;
          }}
        >
          {list2}
        </group>
      </>
    );
  } else {
    return (
      <>
        <group
          scale={10}
          position={[
            200 -
              width3 * unit -
              (width1 * unit) / 2 -
              (props.isLarge ? 12.5 : 0),
            height1 * unit + 30 - props.deltaz,
            dd * unit - (depth * unit) / 2 + 20 + props.deltax,
          ]}
          rotation={[
            !props.origin ? props.alpha : -props.alpha,
            props.theta,
            0,
          ]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID;
          }}
        >
          {list1}
        </group>
        <group
          scale={10}
          position={[
            200 + (width2 * unit) / 2 + (props.isLarge ? 12.5 : 0),
            height1 * unit + 30 - props.deltaz,
            dd * unit - (depth * unit) / 2 + 20 + props.deltax,
          ]}
          rotation={[
            !props.origin ? props.alpha : -props.alpha,
            props.theta,
            0,
          ]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID + 1;
          }}
        >
          {list2}
        </group>

        <group
          scale={10}
          position={[
            200 - (width3 * unit) / 2 - (props.isLarge ? 12.5 : 0),
            height1 * unit + 30 - props.deltaz,
            dd * unit - (depth * unit) / 2 + 20 + props.deltax,
          ]}
          rotation={[
            !props.origin ? props.alpha : -props.alpha,
            props.theta,
            0,
          ]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID + 2;
          }}
        >
          {list3}
        </group>

        <group
          scale={10}
          position={[
            200 +
              (width4 * unit) / 2 +
              width2 * unit +
              (props.isLarge ? 12.5 : 0),
            height1 * unit + 30 - props.deltaz,
            dd * unit - (depth * unit) / 2 + 20 + props.deltax,
          ]}
          rotation={[
            !props.origin ? props.alpha : -props.alpha,
            props.theta,
            0,
          ]}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.editingSubSystem = true;
            state.editingSubSystemID = props.ID - 1;
          }}
        >
          {list4}
        </group>
      </>
    );
  }
}
