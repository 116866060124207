import * as React from 'react';
import state from 'state';
import { lengths, secondHeight } from 'utils/constant';
import { TopDivCount } from 'models/DivCount';

export function CalcCapella(width, t_depth, systemVisible, modelID) {

    var depth = !secondHeight[modelID]?(t_depth + lengths[modelID].column_thickness) / 2 : t_depth;
    if (modelID === 10) depth = (t_depth - 150) / 2;
    const isLarge = width > lengths[modelID].mid_length_limit[0];
    const doubleSystem = !secondHeight[modelID] && (t_depth > 4000);

    const area = width * Math.max(depth, 3000) / 1000 / 1000;
    var cnt = 1;
    var capella_unit = 1;
    var rwidth, width1, width2, width3, width4, div_count = 0;
  
    if (!secondHeight[modelID]) {
        rwidth = width - (isLarge?450:200);
        width1 = rwidth;
        width2 = rwidth;
        width3 = rwidth;
        width4 = rwidth;
        div_count = width <= lengths[modelID].mid_length_limit[0] ? TopDivCount(width, modelID):2*TopDivCount(width / 2, modelID);

        if (area <= 18) state.capellaSize[0] = rwidth;

        if (area > 18 && area <=36) {
            width2 = parseInt(div_count / 2);
            width1 = div_count - width2;
            width1 = rwidth * width1 / div_count;
            width2 = rwidth * width2 / div_count;
            cnt = 2;
            capella_unit = 2;
            state.capellaSize[0] = width1;
            state.capellaSize[1] = width2;
        }
        if (area > 36) {
          width1 = parseInt(div_count / 4);
          width2 = width1;
          width3 = (div_count - width1 * 2) / 2;
          width1 = rwidth * width1 / div_count;
          width3 = rwidth * width3 / div_count;
          width2 = width1;
          width4 = width3;
          cnt = 4;
          capella_unit = 2;
          state.capellaSize[0] = width1;
          state.capellaSize[1] = width3;
        }
      
      
        if (doubleSystem && isLarge) {
          if (!systemVisible[0] || !systemVisible[1] || !systemVisible[2] || !systemVisible[3]) cnt = cnt / 2 * 3;
          else cnt = cnt * 2;
        }

        state.capellaCount = cnt;
        state.capellaUnit = capella_unit;
    }

    else {
        if (modelID === 3 || modelID === 5) {
            if (width >= lengths[modelID].mid_length_limit[0]) div_count = 2 * TopDivCount((width) / 2, modelID);
            if (width >= lengths[modelID].mid_length_limit[1]) div_count = 3 * TopDivCount((width) / 3, modelID);
            if (width < lengths[modelID].mid_length_limit[0]) div_count = TopDivCount(width, modelID);
          }
        else if(modelID === 6 || modelID === 7 || modelID === 10) {
            div_count = TopDivCount(width, modelID);
        }
        width1 = width;
        width2 = width;
        width3 = width;
        if (area <= 18) state.capellaSize[0] = width;
        if (area > 18 && area <= 36) {
            width2 = parseInt(div_count / 2);
            width1 = div_count - width2;
            width1 = width * width1 / div_count;
            width2 = width * width2 / div_count;
            cnt = 2;
            capella_unit = 2;
            state.capellaSize[0] = width1;
            state.capellaSize[1] = width2;
        }
        if (area > 36) {
          const one_area = parseInt(div_count / 3);
          width1 = width3 = one_area;
          width2 = div_count - one_area * 2;
          width1 = width * width1 / div_count;
          width2 = width * width2 / div_count;
          width3 = width * width3 / div_count;
          cnt = 3;
          capella_unit = 3;
          state.capellaSize[0] = width1;
          state.capellaSize[1] = width2;
          state.capellaSize[2] = width3;
      }
      state.capellaCount = modelID === 10 ? cnt * 2 : cnt;
      state.capellaUnit = capella_unit;
    }
}